<div class="board-column-container">
    <div *ngIf="showLabel" class="horizontal-label" [style.width]="labelContainerWidth" [style.height]="labelContainerHeight">
        <span *ngIf="getMonthLabel(week) !== null" class="label" [style.fontSize]="labelSize">{{ getMonthLabel(week) }}</span>
    </div>
    <div class="cell-item" *ngFor="let day of week">
        <board-cell
            [date]="day.date"
            [contributions]="day.contributionsCount"
            [colorIntensity]="day.colorIntensity"
            [options]="options">
        </board-cell>
    </div>
</div>