<div class="board-container">
    <div class="columns">
        <div class="labels-column" *ngIf="showLabels">
            <span [style.fontSize]="labelSize" class="day-label" [style.height]="labelContainerHeight">Fri</span>
            <span [style.fontSize]="labelSize" class="day-label" [style.height]="labelContainerHeight">Wed</span>
            <span [style.fontSize]="labelSize" class="day-label" [style.height]="labelContainerHeight">Mon</span>
        </div>
        <board-column *ngFor="let week of weeks" [week]="week" [options]="options"></board-column>
    </div>
    <div class="legend-container" *ngIf="showLegend && !loading">
        <board-legend [options]="options"></board-legend>
    </div>
</div>