<div class="home-container">
    <div class="title">
        <h2>{{ title }}</h2>
        <p>Display any user's Github contributions board on your angular apps with ease</p>
    </div>
    
    <div id="overview">
        <h3>Overview</h3>
        <ngx-md [path]="'assets/code-samples/overview.sample.html'"></ngx-md>
        <div class="contributions-container">
            <github-board [profile]="profile"></github-board>
        </div>
    
        <p>
            The component suports info on hover, and click callbacks. Optionally, you can specify an options
            object to configure the appearence fo the board. The possible
            customizations size, color scheme, labels size, labels toggle and time span.
        </p>
    </div>
    
    <div id="installation">
        <h3>Installation</h3>
        <p>To install from npm:</p>
        <ngx-md [path]="'assets/code-samples/install.sample.sh'"></ngx-md>
        <p>Import <code>GithubContributionsModule</code> into your app module:</p>
        <ngx-md [path]="'assets/code-samples/install.sample.js'"></ngx-md>
    </div>

    <div id="usage">
        <h3>Usage</h3>
        <p>See example codes and results bellow.</p>
        <h4 class="example-header">Custom colors</h4>
        <p>
            For a custom color palette, you can either specify each field of the
            <code>colorPalette</code> option or you can use one of the presets exported by the
            module thorugh the <code>Themes</code> constant. The available presets are:<br/>
            <code>default:</code> <board-legend></board-legend><br/>
            <code>purple:</code>  <board-legend [options]="{colorPalette: themes.purple}"></board-legend><br/>
            <code>orange:</code>  <board-legend [options]="{colorPalette: themes.orange}"></board-legend><br/>
            <code>red:</code>     <board-legend [options]="{colorPalette: themes.red}"></board-legend><br/>
            <code>blue:</code>    <board-legend [options]="{colorPalette: themes.blue}"></board-legend><br/>
        </p>
        <ngx-md [path]="'assets/code-samples/custom-color.sample.js'"></ngx-md>
        <ngx-md [path]="'assets/code-samples/custom-color.sample.html'"></ngx-md>
        <div class="contributions-container">
            <github-board [profile]="profile" [options]="boardOptions1"></github-board>
        </div>
        <h4 class="example-header">Custom time span</h4>
        <p>The span of weeks shown by the board can also be specified through the <code>weeksNumber</code> option:</p>
        <ngx-md [path]="'assets/code-samples/custom-time-span.sample.js'"></ngx-md>
        <ngx-md [path]="'assets/code-samples/custom-time-span.sample.html'"></ngx-md>
        <div class="contributions-container">
            <github-board [profile]="profile" [options]="boardOptions2"></github-board>
        </div>
        <h4 class="example-header">Custom size</h4>
        <p>
            You can use the <code>cellSize</code> property to set the size in pixels of each board cell.
            This does not affect the spacing between the cells, therefore the bigger the cells,
            the denser the board will look. The <code>labels</code> object will allow you to set a specifc
            size for the day and month labels around the board.
        </p>
        <ngx-md [path]="'assets/code-samples/custom-size.sample.js'"></ngx-md>
        <ngx-md [path]="'assets/code-samples/custom-size.sample.html'"></ngx-md>
        <div class="contributions-container">
            <github-board [profile]="profile" [options]="boardOptions3"></github-board>
        </div>
        <h4 class="example-header">Hiding labels</h4>
        <p>
            Through the <code>labels</code> option you can also hide either the day and/or month labels.
        </p>
        <ngx-md [path]="'assets/code-samples/hiding-labels.sample.js'"></ngx-md>
        <ngx-md [path]="'assets/code-samples/hiding-labels.sample.html'"></ngx-md>
        <div class="contributions-container">
            <github-board [profile]="profile" [options]="boardOptions4"></github-board>
        </div>

        <h4>Options</h4>
    
        <p>The available customization options are:</p>

        <h4 id="color-palette">GithubBoardOptions object</h4>
        <table class="table table-bordered table-striped">
            <thead>
                <tr>
                    <th scope="col">Option</th>
                    <th scope="col">Type</th>
                    <th scope="col">Default Value</th>
                    <th scope="col">Description</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td scope="row">cellSize</td>
                    <td>number</td>
                    <td>10</td>
                    <td>Defines the size of each individual board cell in pixels.</td>
                </tr>
                <tr>
                    <td scope="row">weeksNumber</td>
                    <td>number</td>
                    <td>53</td>
                    <td>Defines the time span shwon on the board in number of weeks.</td>
                </tr>
                <tr>
                    <td id="color-palette-option" scope="row">colorPalette</td>
                    <td><a href="#color-palette">GithubBoardColorPalette</a></td>
                    <td></td>
                    <td>Defines the color palette for the board cells.</td>
                </tr>
                <tr>
                    <td scope="row">labels</td>
                    <td><a href="#label-options">GithubBoardLabelOptions</a></td>
                    <td></td>
                    <td>Defines the properties of the labels indicating day and month around the board.</td>
                </tr>
            </tbody>
        </table>
    
        <h4 id="color-palette">GithubBoardColorPalette object</h4>
        <table class="table table-bordered table-striped">
            <thead>
                <tr>
                    <th scope="col">Option</th>
                    <th scope="col">Type</th>
                    <th scope="col">Default Value</th>
                    <th scope="col">Description</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td scope="row">none</td>
                    <td>string</td>
                    <td>#EBEDF0</td>
                    <td>Defines the color for cells with no contributions.</td>
                </tr>
                <tr>
                    <td scope="row">low</td>
                    <td>string</td>
                    <td>#9BE9A8</td>
                    <td>Defines the color for cells with 1 - 3 contributions.</td>
                </tr>
                <tr>
                    <td scope="row">medium</td>
                    <td>string</td>
                    <td>#40C463</td>
                    <td>Defines the color for cells with 4 - 8 contributions.</td>
                </tr>
                <tr>
                    <td scope="row">high</td>
                    <td>string</td>
                    <td>#30A14E</td>
                    <td>Defines the color for cells with 9 - 10 contributions.</td>
                </tr>
                <tr>
                    <td scope="row">higher</td>
                    <td>string</td>
                    <td>#216E39</td>
                    <td>Defines the color for cells with more than 10 contributions.</td>
                </tr>
            </tbody>
        </table>
        <p>The colors must be specified as strings in any HTML suported format (HEX, rgb, rgba...).</p>
    
        <h4 id="label-options">GithubBoardLabelOptions object</h4>
        <table class="table table-bordered table-striped">
            <thead>
                <tr>
                    <th scope="col">Option</th>
                    <th scope="col">Type</th>
                    <th scope="col">Default Value</th>
                    <th scope="col">Description</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td scope="row">showMonth</td>
                    <td>boolean</td>
                    <td>true</td>
                    <td>Defines whether or not to show month labels.</td>
                </tr>
                <tr>
                    <td scope="row">showDay</td>
                    <td>boolean</td>
                    <td>true</td>
                    <td>Defines whether or not to show day labels.</td>
                </tr>
                <tr>
                    <td scope="row">size</td>
                    <td>number</td>
                    <td>9</td>
                    <td>Defines the font size of the labels in pixels.</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>