<div class="legend">
    <span class="legend-text">Less</span>
    <div class="cells-container">
        <board-cell
            [colorIntensity]="-1"
            [options]="options"
            [disabled]="true">
        </board-cell>
        <board-cell
            [colorIntensity]="0"
            [options]="options"
            [disabled]="true">
        </board-cell>
        <board-cell
            [colorIntensity]="1"
            [options]="options"
            [disabled]="true">
        </board-cell>
        <board-cell
            [colorIntensity]="2"
            [options]="options"
            [disabled]="true">
        </board-cell>
        <board-cell
            [colorIntensity]="3"
            [options]="options"
            [disabled]="true">
        </board-cell>
    </div>
    <span class="legend-text">More</span>
</div>