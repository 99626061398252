
<div
    class="github-board-cell"
    [options]="tooltipOptions"
    [tooltip]="tooltipText"
    [display]="!disabled"
    [class.pointer]="!disabled"
    [style.backgroundColor]="color"
    [style.width]="size"
    [style.height]="size">
</div>
