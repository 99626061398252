<div class="nav-container">
    <a class="nav-item" href="#overview">
        <p>Overview</p>
    </a>
    <a class="nav-item" href="#installation">
        <p>Installation</p>
    </a>
    <a class="nav-item no-border" href="#usage">
        <p>Usage</p>
    </a>
</div>